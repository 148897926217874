// palette for Golden Lady Casino
$bg-color: #1e1000;
$text-color: #e7e5e2;
$primary: #ffc629;
$success: #29FF46;
$info: #AA9354;
$danger: #FF2960;



$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

#rivalHeaderNavAndLogoSection {
  img {
    margin-top: -5px;
    max-height: 50px;
  }
}


//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}
